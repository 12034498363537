<template>
  <div class="root">
    <label v-if="!!label" :class="{ disabled }"> {{ label }}</label>
    <div v-for="claimSet in claimSets" :key="claimSet.key" class="claims">
      <div class="independent">
        <Button v-if="!independentClaimsOnly" class="button" @click="claimSet.expanded = !claimSet.expanded">
          <MdIcon :name="claimSet.expanded ? 'chevron-up' : 'chevron-down'" />
        </Button>
        <Checkbox
          v-model="claimSet.independentClaim.selected"
          :disabled="disabled"
          :label="`${claimSet.independentClaim.claimNumber}. ${claimSet.independentClaim.preamble.text}`"
          @change="e => onSelected(claimSet.independentClaim, e)"
        />
      </div>
      <div v-if="!independentClaimsOnly && claimSet.expanded && !disabled" class="dependent">
        <div v-for="dependentClaim in claimSet.dependentClaims" :key="`${dependentClaim.claimNumber}-${dependentClaim.selected}`">
          <Checkbox
            v-model="dependentClaim.selected"
            :disabled="disabled"
            :label="`${dependentClaim.claimNumber}. ${dependentClaim.preamble.text}`"
            @change="e => onSelected(dependentClaim, e)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '@/components/common/Checkbox';
import Button from '@/components/common/Button';
import MdIcon from '@/components/common/MdIcon';

export default {
  components: {
    MdIcon,
    Checkbox,
    Button
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    context: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let selected;
    const singleSelect = this.item.singleClaimMode;
    const independentClaimsOnly = this.item.independentOnly;
    const value = this.item.value;

    if (!value) {
      selected = [];
    } else {
      selected = Array.isArray(value) ? value : [value];
    }

    let claims = this.context && this.context.document && this.context.document.claims ? this.context.document.claims : [];
    claims = JSON.parse(JSON.stringify(claims));

    for (const claim of claims) {
      claim.selected = this.isSelected(claim, selected);
    }

    const claimSets = [];

    const dependentClaims = claims.filter(c => !c.independentClaim);

    for (const independentClaim of claims.filter(c => c.independentClaim)) {
      const dClaims = [];

      for (const dependentClaim of dependentClaims) {
        if (dependentClaim.parentClaimNumbers && dependentClaim.parentClaimNumbers.includes(independentClaim.claimNumber)) {
          dClaims.push(dependentClaim);
        } else if (dClaims.find(c => dependentClaim.parentClaimNumbers && dependentClaim.parentClaimNumbers.includes(c.claimNumber))) {
          dClaims.push(dependentClaim);
        }
      }
      claimSets.push({
        expanded: dClaims?.find(c => c.selected) ?? false,
        key: independentClaim.claimNumber,
        label: dClaims.length
          ? `Claims ${independentClaim.claimNumber}-${dClaims[dClaims.length - 1].claimNumber}`
          : `Claim ${independentClaim.claimNumber}`,
        independentClaim,
        dependentClaims: dClaims
      });
    }

    return {
      claims,
      claimSets,
      label: this.item.label || this.item.name,
      singleSelect,
      independentClaimsOnly,
      selected
    };
  },
  methods: {
    toggleClaimSet(claimSet) {
      claimSet.expanded = !claimSet.expanded;
    },
    isSelected(claim, selected) {
      if (!selected) {
        return false;
      }

      if (Array.isArray(selected)) {
        return !!selected.find(c => c.claimNumber === claim.claimNumber);
      }

      return selected.claimNumber === claim.claimNumber;
    },
    onSelected(claim, isSelected) {
      if (this.singleSelect) {
        this.claims.forEach(c => (c.selected = false));
      }

      claim.selected = isSelected;

      if (isSelected) {
        if (this.singleSelect) {
          this.selected = [claim];
        } else {
          this.selected.push(claim);
          this.selected = this.selected.sort((a, b) => a.claimNumber - b.claimNumber);
        }
      } else {
        if (this.singleSelect) {
          this.selected = [];
        } else {
          var toRemove = this.selected.find(c => c.claimNumber === claim.claimNumber);
          this.selected.splice(this.selected.indexOf(toRemove), 1);
        }
      }

      this.$emit('input', this.selected);
    }
  }
};
</script>

<style lang="scss" scoped>
.root {
  display: grid;
  grid-gap: 10px;
  border: solid gray 1px;
  padding: 5px;

  label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
  }

  .button {
    padding: 0px;
  }

  .claims {
    display: grid;
    gap: 5px;

    .independent {
      display: grid;
      grid-template-columns: auto 1fr;
    }

    .dependent {
      display: grid;
      gap: 5px;
      padding-left: 35px;
    }
  }
}
</style>
