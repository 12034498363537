import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';
const GET_LIBRARY_CATEGORIES = am(`GET_LIBRARY_CATEGORIES`);
const CREATE_LIBRARY_CATEGORY = am(`CREATE_LIBRARY_CATEGORY`);
const DELETE_LIBRARY_CATEGORY = am(`DELETE_LIBRARY_CATEGORY`);
const GET_LIBRARY_STATISTICS = am(`GET_LIBRARY_STATISTICS`);
const GET_TAGS = am(`GET_TAGS`);
export default {
  namespaced: true,
  ...factory({
    state: {
      categories: [],
      isFetchingCategories: false,
      isCreatingCategory: false,
      isDeletingCategory: false,
      isFetchingStatistics: false,
      statistics: {},
      items: [],
      tags: []
    },
    mutations: {
      [GET_LIBRARY_CATEGORIES.STARTED](state) {
        state.isFetchingCategories = true;
      },
      [GET_LIBRARY_CATEGORIES.COMPLETED](state, categories) {
        state.categories = categories;
        state.isFetchingCategories = false;
      },
      [CREATE_LIBRARY_CATEGORY.STARTED](state) {
        state.isCreatingCategory = true;
      },
      [CREATE_LIBRARY_CATEGORY.COMPLETED](state, category) {
        state.isCreatingCategory = false;
        const index = state.categories.findIndex(c => c.id === category.id);
        if (index === -1) {
          state.categories.push(category);
        } else {
          state.categories.splice(index, 1, category);
        }
      },
      [DELETE_LIBRARY_CATEGORY.STARTED](state) {
        state.isDeletingCategory = true;
      },
      [DELETE_LIBRARY_CATEGORY.COMPLETED](state, id) {
        const index = state.categories.findIndex(c => c.id === id);
        if (index !== -1) {
          state.categories.splice(index, 1);
        }
        state.isDeletingCategory = false;
      },
      [GET_LIBRARY_STATISTICS.STARTED](state) {
        state.isFetchingStatistics = true;
      },
      [GET_LIBRARY_STATISTICS.COMPLETED](state, statistics) {
        state.statistics = statistics;
        state.isFetchingStatistics = false;
      },
      [GET_TAGS.STARTED](state) {
        state.tags = [];
      },
      [GET_TAGS.COMPLETED](state, tags) {
        state.tags = tags;
      }
    },
    actions: {
      async getCategories({ commit }) {
        commit(GET_LIBRARY_CATEGORIES.STARTED);
        const response = await httpClient.get('/api/categories/all?skipOrganizationFiltering=true');
        commit(GET_LIBRARY_CATEGORIES.COMPLETED, response);
      },
      async createCategory({ commit }, { categories, items, ...data }) {
        commit(CREATE_LIBRARY_CATEGORY.STARTED);
        let method;
        if (data.id) {
          method = 'put';
        } else {
          method = 'post';
        }
        const url = data.id ? `/api/categories/${data.id}` : `/api/categories/create`;
        const response = await httpClient[method](url, data);
        commit(CREATE_LIBRARY_CATEGORY.COMPLETED, response);
      },
      async deleteCategory({ commit }, id) {
        commit(DELETE_LIBRARY_CATEGORY.STARTED);
        await httpClient.delete(`/api/categories/${id}`);
        commit(DELETE_LIBRARY_CATEGORY.COMPLETED, id);
      },
      async getStats({ commit }, filters) {
        const params = new URLSearchParams({ ...filters, skipOrganizationFiltering: true });
        commit(GET_LIBRARY_STATISTICS.STARTED);
        const response = await httpClient.get(`/api/library/stats?${params.toString()}`);
        commit(GET_LIBRARY_STATISTICS.COMPLETED, response);
      },
      async getGrouppedItems({ commit }, filters) {
        const params = new URLSearchParams({ ...filters, skipOrganizationFiltering: true });
        return httpClient.get(`/api/library/items?${params.toString()}`);
      },
      async getTags({ commit }) {
        commit(GET_TAGS.STARTED);
        const response = await httpClient.get('/api/categories/tags');
        commit(GET_TAGS.COMPLETED, response);
        return response;
      }
    }
  })('library', {
    async getById(id) {
      return httpClient.get(`/api/library/items/${id}`);
    },
    async getCollection(filter) {
      const params = new URLSearchParams({ ...filter, skipOrganizationFiltering: true });
      return httpClient.get(`/api/library/items?${params.toString()}`);
    },
    async create(data) {},
    async update(id, data) {},
    async delete(id) {}
  })
};
